export interface JobCategory {
  name: string;
  jobs: Job[];
}

export interface Job {
  name: string;
  type: JobType;
  levels: {
    [key in JobLevels]: JobTJM;
  };
}

export interface JobTJM {
  averageLow: number;
  averageMedium: number;
  averageHigh: number;
}

export enum JobLevels {
  JUNIOR = "junior",
  EXPERT = "expert",
  SENIOR = "senior",
}

export enum JobType {
  SELLER = "seller",
  SERVICES = "services",
  LIBERAL = "liberal",
}

export const JOB_LEVELS_MAPPING = {
  junior: "Junior (0-2 ans)",
  expert: "Expert (2-7 ans)",
  senior: "Senior (7+ ans)",
};
