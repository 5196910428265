<template>
  <div class="form">
    <div class="form-field">
      <label>Votre salaire brut annuel en CDI</label>
      <input
        type="number"
        v-model="_grossSalary"
        v-bind:placeholder="_defaultGrossSalary"
      />
    </div>
    <div class="form-field">
      <label>Votre domaine d'activité</label>
      <select v-model="jobCategory">
        <option
          v-bind:value="jobCategory"
          v-for="jobCategory of jobCategories"
          v-bind:key="jobCategory.name"
        >
          {{ jobCategory.name }}
        </option>
      </select>
    </div>
    <div class="form-field" v-if="!!jobCategory">
      <label>Votre métier</label>
      <select v-model="job">
        <option
          v-bind:value="job"
          v-for="job of jobCategory.jobs"
          v-bind:key="job.name"
        >
          {{ job.name }}
        </option>
      </select>
    </div>
    <div class="form-field">
      <label>Votre niveau d'expérience</label>
      <select v-model="experience">
        <option
          v-bind:value="jobLevel"
          v-for="jobLevel of Object.keys(jobLevels)"
          v-bind:key="jobLevel"
          v-bind:selected="jobLevel === experience"
        >
          {{ jobLevels[jobLevel] }}
        </option>
      </select>
    </div>
    <div class="form-field">
      <label>Nombre de jours de travaillés par semaine</label>
      <input
        type="number"
        v-model="_workdaysByWeek"
        v-bind:placeholder="_defaultWorkdaysByWeek"
      />
    </div>
    <div class="form-field">
      <label>Nombre de jours de congés souhaités par année</label>
      <input
        type="number"
        v-model="_daysoffByYear"
        v-bind:placeholder="_defaultDaysoffByYear"
      />
    </div>
    <div class="form-field">
      <label
        >Jours consacrés aux activités non facturées (prospection, suivi de la
        relation client, tâches administratives)</label
      >
      <input
        type="number"
        v-model="_daysfreeByYear"
        v-bind:placeholder="_defaultDaysfreeByYear"
      />
    </div>
    <div class="form-field">
      <label>Coûts frais professionnels mensuel</label>
      <input
        type="number"
        v-model="_feesByMonth"
        v-bind:placeholder="_defaultFeesByMonth"
      />
    </div>
    <div class="form-field">
      <label>Suis-je éligible à l'acre ?</label>
      <input type="checkbox" v-model="acreEligible" />
    </div>
    <div class="form-field" v-if="acreEligible">
      <label>creationDate</label>
      <input
        type="date"
        v-model="creationDateStr"
        v-on:change="updateCreationDate"
      />
    </div>
    <p>
      Jours facturés : {{ workingDays }}<br />
      TJM Brut : {{ grossTJM.toFixed() }}€<br />
      ACRE : {{ acre }}%<br />
      TJM Net : {{ tjm.toFixed() }}€<br />
      <span v-if="tjmAverages"
        >Moyenne basse : {{ tjmAverages.averageLow }}€ / Moyenne :
        {{ tjmAverages.averageMedium }} € / Moyenne haute :
        {{ tjmAverages.averageMedium }}€</span
      ><br />
      Position : {{ position }}
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import { ACRE_DATA, categories } from "@/mock";
import {
  Job,
  JOB_LEVELS_MAPPING,
  JobCategory,
  JobLevels,
  JobTJM,
} from "@/models";
import moment from "moment";

@Options({
  components: {
    HelloWorld,
  },
})
export default class App extends Vue {
  _grossSalary: string | null = null;
  _defaultGrossSalary = "60000";
  get grossSalary(): string {
    return this._grossSalary && this._grossSalary.length > 0 ? this._grossSalary : this._defaultGrossSalary;
  }

  jobLevels = JOB_LEVELS_MAPPING;

  jobCategory: JobCategory | null = null;
  job: Job | null = null;

  experience = JobLevels.JUNIOR;

  _workdaysByWeek: string | null = null;
  _defaultWorkdaysByWeek = "5";
  get workdaysByWeek(): string {
    return this._workdaysByWeek && this._workdaysByWeek.length > 0 ? this._workdaysByWeek : this._defaultWorkdaysByWeek;
  }

  _daysoffByYear: string | null = null;
  _defaultDaysoffByYear = "25";
  get daysoffByYear(): string {
    return this._daysoffByYear && this._daysoffByYear.length > 0 ? this._daysoffByYear : this._defaultDaysoffByYear;
  }

  _daysfreeByYear: string | null = null;
  _defaultDaysfreeByYear = "10";
  get daysfreeByYear(): string {
    return this._daysfreeByYear && this._daysfreeByYear.length > 0 ? this._daysfreeByYear : this._defaultDaysfreeByYear;
  }

  _feesByMonth: string | null = null;
  _defaultFeesByMonth = "50";
  get feesByMonth(): string {
    return this._feesByMonth && this._feesByMonth.length > 0 ? this._feesByMonth : this._defaultFeesByMonth;
  }

  acreEligible = false;
  creationDateStr = moment().format("yyyy-MM-DD");
  creationDate = moment();

  openDays = 249;
  offDays = 12;

  jobCategories = categories;

  get workingDays(): number {
    return (
      (+this.workdaysByWeek ?? 5) * 52 -
      this.offDays -
      +this.daysoffByYear -
      +this.daysfreeByYear
    );
  }

  get grossTJM(): number {
    return +this.grossSalary / this.workingDays;
  }

  get tjm(): number {
    return this.grossTJM - (this.grossTJM * (this.acre / 100)) - (+this.feesByMonth * 12 / this.workingDays);
  }

  public updateCreationDate(event: Event): void {
    if (!event.target) return;
    if (!(event.target instanceof HTMLInputElement)) return;
    if (!event.target.valueAsDate) return;
    this.creationDate = moment(event.target.valueAsDate);
  }

  get acre(): number {
    console.log(this.acreEligible, this.creationDate, this.job);
    if (!this.acreEligible) return 22;
    if (!this.creationDate) return 22;
    if (!this.job) return 22;

    const range =
      this.creationDate.year() < 2020 ? ACRE_DATA.before : ACRE_DATA.after;

    let diff = -this.creationDate.diff(moment(), "years");
    if (diff < 0) diff = 0;

    let percentage = range[this.job.type][diff];
    if (!percentage)
      percentage = range[this.job.type][range[this.job.type].length - 1];

    return percentage;
  }

  public get tjmAverages(): JobTJM | null {
    if (!this.job || !this.experience) return null;

    return this.job.levels[this.experience];
  }

  public get position(): string {
    const averages = this.tjmAverages;
    const tjm = this.grossTJM;
    if (!tjm || !averages) return "";
    if (tjm < averages.averageLow) {
      return "Très compétitif";
    }
    if (tjm <= averages.averageMedium) {
      return "Compétitif";
    }
    if (tjm <= averages.averageHigh) {
      return "Peu compétitif";
    }
    if (tjm > averages.averageHigh) {
      return "Très peu compétitif";
    }

    return "";
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-field {
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
</style>
