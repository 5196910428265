import { JobCategory, JobType } from "@/models";

export const categories: JobCategory[] = [
  {
    name: "Développeur",
    jobs: [
      {
        name: "Développeur Scala",
        type: JobType.LIBERAL,
        levels: {
          junior: {
            averageLow: 120,
            averageMedium: 301,
            averageHigh: 510,
          },
          expert: {
            averageLow: 250,
            averageMedium: 522,
            averageHigh: 810,
          },
          senior: {
            averageLow: 400,
            averageMedium: 671,
            averageHigh: 1010,
          },
        },
      },
      {
        name: "Développeur Microservices",
        type: JobType.LIBERAL,
        levels: {
          junior: {
            averageLow: 120,
            averageMedium: 297,
            averageHigh: 510,
          },
          expert: {
            averageLow: 200,
            averageMedium: 509,
            averageHigh: 810,
          },
          senior: {
            averageLow: 400,
            averageMedium: 674,
            averageHigh: 1210,
          },
        },
      },
    ],
  },
];

export const ACRE_DATA: {
  before: {
    [key in JobType]: number[];
  };
  after: {
    [key in JobType]: number[];
  };
} = {
  before: {
    seller: [3.2, 9.6, 11.6, 12.8],
    services: [5.5, 16.5, 19.8, 22],
    liberal: [5.5, 16.5, 19.8, 22],
  },
  after: {
    seller: [6.4, 12.8],
    services: [11, 22],
    liberal: [11, 22],
  },
};
